import * as React from 'react';
import './styles/Services.css';
import { Col, Row, Container } from 'react-bootstrap';
import { ServiceCardData } from '../common/data';
import ServiceCard from './ServiceCard';

const Services = () => {
    return (
        <section className="services-container flex-align-center flex-column section">
            <div className='services-overlay'></div>
            <Container className='section-container' style={{ zIndex: '3' }}>
                <h5>OUR SERVICES</h5>
                <h1>Exposed Unknown Threats Become a Problem</h1>
                <p className="section-description">Our comprehensive cybersecurity offerings include disaster recovery, rapid response, penetration testing, data security management, and user awareness training.</p>

                <Row>
                    {ServiceCardData.map((service, i) => (
                        <Col md={4} xs={12} className='d-flex justify-content-center' key={i}>
                            <ServiceCard
                                icon={service.icon}
                                iconAlt={`icon` + i}
                                title={service.title}
                                desc={service.desc}
                                key={i}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </section >
    );
}

export default Services;