import React, { useRef } from 'react';
import './styles/Home.css';
import { Col, Row, Container } from 'react-bootstrap';
import heroImg from '../assets/images/hero-section-img.png';
import { MdOutlineLocalPhone } from "react-icons/md";
import Services from '../components/Services';
import WelcomeSection from '../components/WelcomeSection';
import ContactUs from '../components/ContactUs';
import NewsSection from '../components/NewsSection';
//import Testimonials from '../components/Testimonials';
import heroBg from '../assets/videos/hero-section-bg.mp4'
import Promotion from '../components/Promotion';


const Home = () => {
    const contactRef = useRef(null);

    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <section className='hero-section-container flex-align-center section'>
                <video src={heroBg} autoPlay loop muted playsInline className="video-background" />
                <div className='overlay-blue'></div>
                <div className='overlay-black'></div>
                <Container className='section-container' style={{ position: 'relative', zIndex: '5' }}>
                    <Row className='hero-section-row' >
                        <Col md={6} xs={12} className='d-flex justify-content-center align-items-left flex-column'>
                            <h1>Experiencing a disaster?</h1>
                            <h5>Cyber Recovery as a Service</h5>
                            <p className='section-description'>Prepare for Cyber Threats: Safeguard your digital world with our expert guidance and emergency response strategies</p>
                            <button  onClick={scrollToContact} className='hero-section-btn'>
                                <Row className='hero-section-btn-row'>
                                    <Col className='flex-align-center' xs={2} ><MdOutlineLocalPhone className='hero-btn-icon' /></Col>
                                    <Col className='p-0 me-4' md={4} xs={9} style={{ zIndex: '4' }}>
                                        <p className='hero-btn-txt-1 m-0'>Contact RARE</p>
                                        <p className='hero-btn-txt-2 m-0'>RApid REsponse Team</p>
                                    </Col>
                                    <Col className='p-0 hero-btn-emergency-txt' md={5} style={{ zIndex: '4' }}>
                                        <p className='hero-btn-txt-3 mb-2'>Cyber Threat</p>
                                        <p className='hero-btn-txt-4 m-0'>EMERGENCY</p>
                                    </Col>
                                </Row>
                            </button>
                        </Col>
                        <Col md={6} xs={12} className='flex-align-center flex-column'>
                            <img
                                src={heroImg}
                                className="hero-img-wrapper"
                                alt="Logo"
                            />
                        </Col>
                    </Row>
                </Container>
            </section >
            <WelcomeSection contactRef={contactRef} />
            <Services />
            <NewsSection />
            <Promotion contactRef={contactRef} />
            <ContactUs contactRef={contactRef} />
        </>
    );
}

export default Home;