import * as React from 'react';
import { newsList } from '../common/data';
import { Col, Row } from 'react-bootstrap';
import NewsCard from './NewsCard';
import './styles/NewsSection.css';

const NewsSection = () => {
    return (
        <section className='news-section flex-align-center flex-column'>
            <h5>LATEST NEWS</h5>
            <h1 className='mt-4'>Our News From Blackshield Cyber</h1>
            <p className='section-description news-desc'>Stay informed with the latest cybersecurity updates, threat analyses, and industry insights in our comprehensive news section.</p>
            <Row className='news-section-row'>
                {newsList.map((news, i) => (
                    <Col md={4} xs={12} className='d-flex justify-content-center' key={i}>
                        <NewsCard
                            img={news.icon}
                            title={news.title}
                            desc={news.desc}
                            key={i}
                        />
                    </Col>
                ))}
            </Row>
        </section>
    );
}

export default NewsSection;