import * as React from 'react';
import './styles/WelcomeSection.css';
import { Col, Row, Container } from 'react-bootstrap';
import { FeatureList } from '../common/data';
import ServiceCard from './ServiceCard';

const WelcomeSection = ({ contactRef }) => {

    const scrollToContact = () => {
        console.log(contactRef);
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };


    return (
        <div className='welcome-section-container flex-align-center flex-column'>
            <div className='welcome-section-overlay'></div>
            <section className='who-we-serve-wrapper'>
                <Container className='who-we-serve-section m-0'>
                    <Row className='who-we-serve-row'>
                        <Col className='welcome-img-col' md={5} xs={12}>
                            <div className='welcome-section-img'></div>
                            <div className='section-float-div'>
                                <p className='float-div-count m-0'>85+</p>
                                <p className='m-0'>Trusted Companies</p>
                            </div>

                        </Col>
                        <Col md={7} xs={12} className='ps-3 d-flex flex-column justify-content-between who-we-serve-col'>
                            <h5>Who we Serve</h5>
                            <h1>We Serve a vast number of different industries</h1>
                            <p className='section-description'>We Provide Cyber Warfare Defense & Compliance Management solutions to Small to Medium Enterprises within the 16 critical industries defined by the Department of Homeland Security.
                             We protect the things that make the world go around.</p>
                            <Row className='industry-list'>
                                <Col md={4} xs={12} className='p-0'>
                                    <ul className='section-industry-list'>
                                        <li>Chemical</li>
                                        <li>Commercial Facilities</li>
                                        <li>Communications</li>
                                        <li>Critical Manufacturing</li>
                                    </ul>
                                </Col>
                                <Col md={4} xs={12} className='p-0'>
                                    <ul className='section-industry-list'>
                                        <li>Defence Industrial Base</li>
                                        <li>Emergency Services</li>
                                        <li>Energy</li>
                                        <li>Financial Services</li>
                                    </ul>
                                </Col>
                                <Col md={4} xs={12} className='p-0'>
                                    <ul className='section-industry-list'>
                                        <li>Food and Agriculture</li>
                                        <li>Government Facilities</li>
                                        <li>Healthcare & Public Health</li>
                                        <li>Transportation Systems</li>
                                        <li>Water and Wastewater Systems</li>
                                    </ul>
                                </Col>
                            </Row>
                            <button className='app-main-btn' onClick={scrollToContact} ><span className='app-main-btn-txt'>Join with us</span></button>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='why-choose-us-wrapper'>
                <Container className='why-choose-us-container'>
                    <Row>
                        <Col md={6} xs={12} className='why-us-col'>
                            <h5>WHY CHOOSE US</h5>
                            <h1>Scalable and Cost Effective</h1>
                            <p className='section-description'>Choose Black Shield Cyber for unparalleled cybersecurity expertise, cutting-edge solutions, and a commitment to safeguarding your digital landscape against evolving threats.</p>
                            <button className='app-main-btn' onClick={scrollToContact}><span className='app-main-btn-txt'>Contact us</span></button>
                        </Col>
                        <Col md={6} xs={12}>
                            <Row className='service-card-row'>
                                {FeatureList.map((feature, i) => (
                                    <Col md={6} xs={12} className='d-flex justify-content-center' key={i}>
                                        <ServiceCard
                                            icon={feature.icon}
                                            iconAlt={`icon` + i}
                                            title={feature.title}
                                            desc={feature.desc}
                                            key={i}
                                            align='left'
                                            size='small'
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div >
    );
}

export default WelcomeSection;