import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import logo from '../assets/logo-white.png';

const AppBar = () => {
  return (
    <Navbar className='app-bar'>
      <Navbar.Brand href="/" className="mx-auto">
        <img
          src={logo}
          className="d-inline-block align-top app-bar-logo"
          alt="Logo"
        />
      </Navbar.Brand>
    </Navbar>
  );
}

export default AppBar;