import React from 'react';
import './styles/ContactUs.css'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';


const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    subject: yup.string().required('Subject is required'),
    message: yup.string().required('Message is required'),
    recaptcha: yup.string().required('Please complete the reCAPTCHA')
});

const ContactUsForm = () => {
    const { register, handleSubmit, formState: { errors }, reset, setValue, clearErrors } = useForm({
        resolver: yupResolver(schema),
    });


    const onSubmit = (data) => {
		//send email via smtp
		console.log(data);

		//send data to api as json object
		fetch('https://beta.account.babysonbroadway.com/change', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
		
        reset();
    };

    const onCaptchaChange = (value) => {
        setValue('recaptcha', value);
        clearErrors('recaptcha');
    };



    return (
        <div className='contact-form-wrapper'>
            <div>
                <h1 className='font-blue'>Got Any Questions?</h1>
                <p className='m-0 mb-5 text-start'>Do not hesitate to reach us.</p>
            </div>
            <Form className='contact-form-container' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="contact-form.input1">
                    <Form.Control
                        className='contact-form-input'
                        type="text"
                        placeholder="Your Name"
                        {...register('name')}
                        isInvalid={errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.name?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="contact-form.input2">
                    <Form.Control
                        className='contact-form-input'
                        type="email"
                        placeholder="Your Email"
                        {...register('email')}
                        isInvalid={errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.email?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="contact-form.input3">
                    <Form.Control
                        className='contact-form-input'
                        type="text"
                        placeholder="Your Subject"
                        {...register('subject')}
                        isInvalid={errors.subject}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.subject?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="contact-form.ControlTextarea1">
                    <Form.Control
                        className='contact-form-input'
                        as="textarea"
                        placeholder='Your Messages'
                        rows={3}
                        {...register('message')}
                        isInvalid={errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.message?.message}
                    </Form.Control.Feedback>
                </Form.Group>
                <ReCAPTCHA
                    sitekey='6LfnEuEpAAAAAENpaPKYrjMDWWlCeTx873qaJVjw'
                    onChange={onCaptchaChange}
                />
                {errors.recaptcha && (
                    <p className="text-danger m-0">{errors.recaptcha.message}</p>
                )}

                <a href="mailto:dalexandre@blackshieldcyber.com">
                    <button className='app-main-btn' type="submit">
                        <span className='app-main-btn-txt'>Send message</span>
                    </button>
                </a>
            </Form>
        </div>
    );
}

export default ContactUsForm;