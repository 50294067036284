import React from 'react';
import { Row } from 'react-bootstrap';
import './styles/Promotion.css'

const Promotion = ({ contactRef }) => {

    const scrollToContact = () => {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <section className='promotion-section'>
            <div className='promotion-overlay'></div>
            <Row xs={12} className='promotion-card'>
                <h5>PROMOTION</h5>
                <h2>Contact us for to Help You in Security Solutions</h2>
                <p>Elevate your digital safety with our cutting-edge cybersecurity services; safeguarding your data is our topmost priority.</p>
                <button className='app-main-btn' onClick={scrollToContact}><span className='app-main-btn-txt'>Contact us</span></button>
            </Row>
            
        </section>
    );
}

export default Promotion;