// Service Icons
import icon1 from '../assets/icons/icon-service-1.png';
import icon2 from '../assets/icons/icon-service-2.png';
import icon3 from '../assets/icons/icon-service-3.png';
import icon4 from '../assets/icons/icon-service-4.png';
import icon5 from '../assets/icons/icon-service-5.png';
import icon6 from '../assets/icons/icon-service-6.png';

// Feature Icons
import ficon1 from '../assets/icons/icon-feature-1.png';
import ficon2 from '../assets/icons/icon-feature-2.png';
import ficon3 from '../assets/icons/icon-feature-3.png';
import ficon4 from '../assets/icons/icon-feature-4.png';

// Contact Icons
import iconPhone from '../assets/icons/icon-phone.png';
import iconEmail from '../assets/icons/icon-email.png';
import iconWeb from '../assets/icons/icon-web.png';
import iconLocation from '../assets/icons/icon-location.png';

// News Icons
import news1 from './images/news1.png';
import news2 from './images/news2.png';
import news3 from './images/news3.png';

const images = {
  services: {
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
  },
  features: {
    ficon1,
    ficon2,
    ficon3,
    ficon4,
  },
  contact: {
    phone: iconPhone,
    email: iconEmail,
    web: iconWeb,
    location: iconLocation,
  },
  news: {
    news1,
    news2,
    news3,
  }
};

export default images;