import images from "../assets";

export const ServiceCardData = [
    {
        icon: images.services.icon1,
        title: "CWOD (CyberWarfare Operations Department)",
        desc: "Provides disaster recovery, rapid response, and forensics analysis for cyber incidents."
    },
    {
        icon: images.services.icon2,
        title: "GPE CWOD (Government Public Enterprise CyberWarfare Operations Department)",
        desc: "Similar to CWOD, but specifically serves government public enterprises."
    },
    {
        icon: images.services.icon3,
        title: "Special Operations",
        desc: "Offers disaster recovery, rapid response, and forensics analysis."
    },
    {
        icon: images.services.icon4,
        title: "Sentinels",
        desc: "Sentinels focus on assessing vulnerabilities, identifying weaknesses, and improving cybersecurity defenses"
    },
    {
        icon: images.services.icon5,
        title: "Pallisade Department",
        desc: "Ensures data security for state information hosted in data centers, serving Arizona and California."
    },
    {
        icon: images.services.icon6,
        title: "Cybersecurity Training and Awareness",
        desc: "Focuses on educating employees and users about cybersecurity best practices, threat awareness, and safe online behavior. "
    }
]

export const FeatureList = [
    {
        icon: images.features.ficon1,
        title: "Scale Indefinitely",
        desc: "Scale indefinitely with us; experience seamless growth and adaptability in your cybersecurity infrastructure."
    },
    {
        icon: images.features.ficon2,
        title: "Improve Your Security",
        desc: "Enhance your defense with our robust cybersecurity measures; stay ahead of threats and protect your assets"
    },
    {
        icon: images.features.ficon3,
        title: "Visibility Every Branch",
        desc: "Ensure consistent security visibility across all branches for comprehensive protection."
    },
    {
        icon: images.features.ficon4,
        title: "Automate Routine",
        desc: "Automate routine tasks to enhance efficiency and focus on critical security issues"
    },
]

export const ContactList = [
    {
        icon: images.contact.phone,
        title: "Phone Number",
        desc: "123-234-1234"
    },
    {
        icon: images.contact.email,
        title: "Email Address",
        desc: "hello@blackshieldcyber.com"
    },
    {
        icon: images.contact.web,
        title: "Website",
        desc: "www.blackshieldcyber.com"
    },
    {
        icon: images.contact.location,
        title: "Address",
        desc: "99 Roving St., Big City, PKU 23456"
    },
]

export const newsList = [
    {
        icon: images.news.news1,
        title: "Cryptography - What is it and How it affect",
        desc: "Cryptography involves securing information by converting it into a code or cipher, ensuring confidentiality and integrity during data transmission."
    },
    {
        icon: images.news.news2,
        title: "Cybersecurity Education and Workforce Development",
        desc: " Focused on training and preparing professionals to defend against cyber threats. It includes certifications, workshops, and skill development."
    },
    {
        icon: images.news.news3,
        title: "Securing Emerging Technologies",
        desc: "Addresses the challenges of securing new technologies like IoT, AI, and quantum computing. Balancing innovation with security is crucial."
    },
]