import * as React from 'react';

const ServiceCard = ({ icon, title, desc, iconAlt, align = 'center', size = 'normal' }) => {
    return (
        <div className={`service-card ${align === 'left' ? 'align-items-start text-left' : 'align-items-center text-center'} 
        ${size === 'small' ? 'service-card-size-small' : 'service-card-size-normal'}`}>
            <img
                src={icon}
                className={`service-card-icon ${size === 'small' ? 'service-card-icon-small' : 'service-card-icon-normal'}`}
                alt={iconAlt}
            />
            <h4 className={align === 'left' ? 'text-start' : ''}>{title}</h4>
            <p>{desc}</p>
        </div>
    );
}

export default ServiceCard;