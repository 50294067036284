import React from 'react';
import './styles/ContactUs.css'
import { Col, Row, Container } from 'react-bootstrap';
import ContactUsForm from './ContactUsForm';

const ContactUs = ({ contactRef }) => {

    return (
        <section className='contact-us-section flex-align-center' id="contact-us-section" ref={contactRef}>
        <Container className='contact-container'>
            <h5>contact us</h5>
            <h1 className='mt-5'>Get In Touch</h1>
            <p className='section-description contact-desc'>
                Connect with our cybersecurity experts today. we're here to assist you 24/7 with any inquiries or support needs.
            </p>
            <Row>
                <Col md={6} xs={12}>

                    <ContactUsForm />
                </Col>
            </Row>
        </Container>
    </section>
    );
}

export default ContactUs;