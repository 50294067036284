import * as React from 'react';
import { Card } from 'react-bootstrap';

const NewsCard = ({ img, title, desc }) => {
  return (
    <Card className='news-card' style={{ width: '370px', position: 'relative' }} >
      <Card.Img variant="top" src={img} />
      <div className='news-card-float'>Tips and Trick</div>
      <Card.Body className='news-card-body'>
        <Card.Title style={{ fontFamily: 'Jost' }}>{title}</Card.Title>
        <Card.Text>{desc}</Card.Text>
      </Card.Body>
    </Card>
  );
}


export default NewsCard;