import * as React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/logo-white.png';
import { BsFacebook, BsLinkedin  } from "react-icons/bs";

const Footer = () => {
  return (
    <footer className='app-footer'>
      <Container className='app-footer-container'>
        <Row className='app-footer-row'>
          <Col md={6} xs={12} className='footer-col-left'>
            <Navbar.Brand href="/">
              <img
                src={logo}
                className="app-footer-logo"
                alt="Logo"
              />
            </Navbar.Brand>
          </Col>
          <Col md={6} xs={12} className='footer-col-right'>
            <div className='footer-icon-wrapper'>
              <button
                className='footer-media-icon'
                onClick={() => window.open('https://m.facebook.com/BlackShieldCyber', '_blank')}
                aria-label='Facebook'
              >
                <BsFacebook />
              </button>
              <button
                className='footer-media-icon'
                onClick={() => window.open('https://www.linkedin.com/company/blackshieldcyberinc/', '_blank')}
                aria-label='LinkedIn'
              >
                <BsLinkedin  />
              </button>
            </div>
          </Col>
        </Row>
        <Row className='app-footer-bottom'>
          <p className='m-0'>Copyright 2024 © All Right Reserved Design by 10XTEK</p>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;